'use client'; // Ensure it's a client component if you're using context
import React, { useEffect, useState } from 'react';
import { LayoutContextProvider } from '@/useLayout';
import { useParams } from 'next/navigation';

export default function RootLayout({
    children,
}: {
    children: React.ReactNode;
}) {

    const params = useParams();
    const { lng } = params;

    // State to handle the language value
    const [language, setLanguage] = useState<any>(process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE);
    useEffect(() => {
        // If lng exists in the query, update the state
        if (lng) {
            const languageCode = String(lng).toLowerCase();
            setLanguage(languageCode);

        }
    }, [lng]);

    return (
        <LayoutContextProvider lng={language}>
            {children}
        </LayoutContextProvider>
    );
}