"use client";

import { createContext, useContext } from 'react';

const LayoutContext = createContext<{ lng: string } | undefined>(undefined);

export const useLayoutContext = () => {
    const context = useContext(LayoutContext);
    if (!context) throw new Error('useLayoutContext must be used within LayoutContextProvider');
    return context;
};

export const LayoutContextProvider = ({ children, lng }: { children: React.ReactNode; lng: any }) => {
    const contextValue = { lng };

    return (
        <LayoutContext.Provider value={contextValue}>
            {children}
        </LayoutContext.Provider>
    );
};